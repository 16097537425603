<template>
    <div class="sidebar__menu">
        <header-dropdown/>
    </div>
</template>

<script>
export default {
    name: "Panel",
    components: {
        HeaderDropdown : () => import('./HeaderDropdown')
    }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.sidebar {
  &__menu {
    height: 100%;
  }
}
</style>
